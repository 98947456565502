import styled from 'styled-components';

export const ContactCard = styled.div`
  margin-bottom: 45px;

  svg {
    display: block;
    margin: 0 auto 45px;
  }

  a {
    color: #9b87dd;
    text-decoration: none;
    display: block;
    margin: 5px 0;
  }
`;
