import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Inbox, Smartphone, ThumbsUp } from 'react-feather';

import Layout from '../components/Layout';
import Breaker from '../components/Breaker';
import { Title } from '../styles/base';
import { ContactCard } from '../styles/contacts';
import { KEYWORDS } from '../constants/Keywords';
import SEO from '../components/seo';

const Contacts = () => (
  <Layout>
    <SEO title="Contactos" keywords={[...KEYWORDS]} />

    <Title>Diga olá.</Title>
    <div style={{
      color: '#999',
      textAlign: 'center',
      fontSize: 14,
      marginTop: 30
    }}>
      Nada melhor do que falarmos para {' '}
      esclarer quaisquer dúvidas.
    </div>
    <Breaker />

    <Grid>
      <Row center='md' middle='md'>
        <Col md={10}>
          <Row center='xs'>
            <Col md={4}>
              <ContactCard>
                <Inbox color='#9b87dd' size={48} />

                <a href='mailto:hd.photographers@gmail.com'>
                  E-mail
                </a>
              </ContactCard>
            </Col>

            <Col md={4}>
              <ContactCard>
                <Smartphone color='#9b87dd' size={48} />

                <a href='tel:916793411'>
                  916 793 411
                </a>
              </ContactCard>
            </Col>

            <Col md={4}>
              <ContactCard>
                <ThumbsUp color='#9b87dd' size={48} />

                <a href='https://www.facebook.com/hdphotographers/' target='_blank' rel="noopener noreferrer">
                  Facebook
                </a>
              </ContactCard>
            </Col>
          </Row>
        </Col>
      </Row>
    </Grid>
  </Layout>
);

export default Contacts;
